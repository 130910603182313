<template>
  <div class="topTitle">
    <span class="title_border"></span>
    <span class="title_text">{{ headname }}</span>
    <span v-show="count>0">（{{ count }}）</span>
  </div>
</template>

<script>
export default {
  props: {
    headname: {
      type: String,
      require: true,
      default: () => ''
    },
    count: {
      type: Number,
      default: () => 0
    }
  }
}
</script>

<style lang="less" scoped>
.topTitle {
  height: 55px;
  width: 100%;
  line-height: 20px;
  font-size: 19px;
  position: relative;
  text-align: left;
  .title_text {
    display: inline-block;
    margin-left: 9px;
    margin-top: 25px;
    // width: 80px;
    height: 30px;
    line-height: 30px;
    font-family: 'PingFangSC-Regular';
    font-size: 19px;
    color: #444444;
  }
}
.title_border {
  float: left;
  height: 20px;
  margin-top: 30px;
  margin-left: 30px;
  width: 4px;
  background: #ee2e2e;
}
</style>
