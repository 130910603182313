<!-- 最新版评测 -->
<template>
  <div class="review_main">
    <headers ref="headers" :headname="headName"></headers>
    <div v-if="list&&list.length>0" class="list">
      <div v-for="(item,index) in list" :key="index" class="item">
        <div class="top_tag">
          <span v-if="item.answer_type" :style="`background-color:${colors[item.answer_type-1].color}`">{{ colors[item.answer_type-1].title }}</span>
          <div v-show="item.express_no && item.express_no.length > 0" class="div_right" @click="checkTheExpress(item)">
            <img src="@/assets/car.png" alt="" style="width: 28px;height: 28px">
            <span style="color: #ee2e2e;display: inline-block;margin-top: 5px">查看物流</span>
          </div>
        </div>

        <div class="item_info">
          <div class="info_left">
            <div class="title_box">
              <div class="title">评测名称：{{ item.name }}</div>
              <div class="number">共 {{ item.exercise_num }} 题，答对 <span class="red">{{ item.correct_num }}</span> 题</div>
              <div class="time">评测时间：{{ item.create_time }}</div>
            </div>
            <div class="score_box">
              <span v-if="item.is_pass===1" class="circle">{{ item.score }}</span>
              <span v-else class="circle1">{{ item.score }}</span>
              <div v-show="item.score>0">已超 <span class="red">{{ item.user_beyond || 0 }}%</span> 的学员</div>
            </div>
          </div>
          <div class="info_right">
            <span v-if="item.answer_url && item.answer_url.length > 0" class="button" @click="checkTheParsing(item)">查看解析</span>
            <div class="get_cer">
              <span v-show="item.cert_pay_status == 1 && item.cert_url" style="margin-left: 15px;" class="button" @click="goCertUrl(item.cert_url)">查看证书</span>
              <span v-show="item.cert_pay_status == 2" class="button" style="margin-left: 15px;" @click="showTip(item,1)">领取证书</span>
              <span v-show="item.cert_pay_status == 2 && item.electron_cert_url" style="cursor: pointer;margin-left: 15px;" class="electronicVer" @click="showTip(item,2)">查看电子版</span>
            </div>
            <span v-if="item.afresh_url && item.afresh_url.length > 0" class="button1" style="margin-left: 15px;" @click="reviewAgin(item)">重新评测</span>
          </div>
        </div>
      </div>
      <el-pagination v-if="count > 10" class="page" background :total="count" layout="prev, pager, next" :page-size="limit" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <noData v-else ref="noData" :msg="msg"></noData>
    <el-dialog
      title="温馨提示"
      :visible.sync="showInfoDialog"
      style="margin-top: 120px;margin-left: 140px"
      width="34%"
    >
      <div style="text-align: left">
        <p>您在个人信息中填写的真实姓名为：<span style="font-weight: 600">{{ realName }}</span></p>
        <p style="margin-top: 8px">此信息将用于发放评测证书，是否确认？</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goModify">去修改</el-button>
        <el-button type="primary" @click="editSure">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
import { getUserReviewListNew, upReviewCertUsername } from '@/api/evaluate.js'
export default {
  components: { headers, noData },
  data() {
    return {
      realName: '',
      dialog_data: '',
      dialog_type: 1,
      showInfoDialog: false,
      real_name: '',
      headName: '我的评测',
      msg: '您还没有评测哦~',
      list: [],
      page: 1,
      limit: 10,
      count: 0,
      colors: [
        {
          title: '随堂练习',
          color: '#2CB585'
        },
        {
          title: '固定时间模式',
          color: '#3BA4E6'
        },
        {
          title: '竞赛模式',
          color: '#EC4848'
        },
        {
          title: '随到随考模式',
          color: '#F59A21'
        },
        {
          title: '随堂评测模式',
          color: '#30BCBC'
        }
      ]
    }
  },
  created() {
    this.getDataLists()
  },
  methods: {
    goModify() {
      this.showInfoDialog = false
      this.$router.push({
        path: '/registinfo'
      })
    },
    editSure() {
      upReviewCertUsername({ evaluation_id: this.evaluation_id }).then(res => {
        if (res.errNo === 0) {
          this.getDataLists()
          this.showInfoDialog = false
          if (this.dialog_type === 1) {
            this.goUrl(this.dialog_data)
          } else if (this.dialog_type === 2) {
            this.goCertUrl(this.dialog_data.electron_cert_url)
          }
        }
      })
    },
    // 证书领取，去支付
    goUrl(item) {
      window.open(item.buy_url, '_blank')
    },
    // 查看证书
    goCertUrl(url) {
      window.open(url)
    },
    showTip(data, type) {
      this.evaluation_id = ''
      this.dialog_data = data
      this.dialog_type = type
      if (data.cert_username && data.cert_username.length > 0) {
        if (this.dialog_type === 1) {
          // 领取证书
          this.goUrl(this.dialog_data)
        } else if (this.dialog_type === 2) {
          // 查看电子版
          this.goCertUrl(this.dialog_data.electron_cert_url)
        }
      } else {
        // 没有名字，需要确认
        this.evaluation_id = data.evaluation_id
        this.realName = data.real_name || ''
        this.showInfoDialog = true
      }
    },
    // 获取列表
    getDataLists() {
      const q = {
        page: this.page,
        limit: this.limit
      }
      getUserReviewListNew(q).then(res => {
        if (res.errNo === 0) {
          const result = res.result || {}
          const list = result.list || []
          this.list = list
          this.count = result.count
        }
      })
    },
    handleCurrentChange(val) {
      this.page = val
      this.getDataLists()
    },
    checkTheParsing(item) {
      const url = item.answer_url
      window.open(url)
    },

    checkTheExpress(item) {
      this.$router.push({ path: '/express' })
    },
    // 重新评测
    reviewAgin(item) {
      const url = item.afresh_url
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/.el-dialog__header{
    text-align: left;
    font-weight: 600;
  }
  .review_main{
    width: 100%;
  }
  .list{
    width: 100%;
    padding: 30px 38px;
  }
  .item{
    margin-bottom: 20px;
    padding-bottom: 16px;
    box-shadow:0px 0px 6px 2px #aaa;
  }
  .top_tag{
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
    span{
      display: inline-block;
      padding: 1px 10px;
      color: white;
      font-family: 'PingFangSC-Medium';
    }
    .div_right{
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .item_info{
    // height: 100px;
    display: flex;
    justify-content:space-between;
  }
  .info_left{
    height: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding-left: 30px;
    .title_box{
      width: 300px;
    }
    .title{
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 10px;
    }
    .number{
      margin-bottom: 10px;
    }

    .score_box{
      margin-left: 10px;
      width: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .circle,.circle1{
        text-align: center;
        width: 50px;
        height: 50px;
        line-height: 44px;
        border-radius: 50%;
        display: inline-block;
        border: 3px solid #ee2e2e;
        font-family: 'PingFangSC-Medium';
        font-size: 22px;
        color: #ee2e2e;
        margin-bottom: 10px;
      }
      .circle1{
        border: 3px solid #999999;
        color: #999999;
      }
    }
  }

  .red{
    color: #ee2e2e;
  }

  .info_right{
    width: 40%;
    padding-top: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    .button,.button1{
      display: inline-block;
      width: 100px;
      background-color: #ee2e2e;
      height: 40px;
      font-size: 15px;
      color: white;
      line-height: 40px;
      border-radius: 20px;
      // margin-right: 20px;
      cursor: pointer;
    }
    .button1{
      border: 1px solid #ee2e2e;
      color: #ee2e2e;
      background-color: white;
      line-height: 38px;
      //margin-right: 20px;
    }
    .get_cer{
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      //margin-right: 20px;
      .electronicVer{
        margin-top: 10px;
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        text-decoration: underline;
      }
    }
  }
</style>
