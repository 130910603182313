<template>
  <div class="showNoData">
    <img :src="img" alt="">
    <p>{{ msg }}</p>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      require: true,
      default: () => ''
    },
    img: {
      type: String,
      // require: true,
      default: () => require('@/assets/image/kong.png')
    }
  }
}
</script>

<style lang="less" scoped>
.showNoData{
	position: absolute;
	width: 220px;
	height: 200px;
	top: 50%;
	left: 50%;
	margin-top: -110px;
  margin-left: -110px;
}
.showNoData img{
  width: 140px;
  height: 140px;
	display: block;
	margin: auto;
}
.showNoData p{
	text-align: center;
	font-size: 14px;
	color: #999999;
  height: 30px;
  line-height: 30px;
	margin-top: 30px;
}
</style>
