import request from '@/utils/requestZs'

export function getUserReviewListNew(params) {
  return request({
    url: 'review/review/getUserReviewListNew',
    method: 'get',
    params
  })
}
export function upReviewCertUsername(params) {
  return request({
    url: '/review/review/upReviewCertUsername',
    method: 'get',
    params
  })
}
